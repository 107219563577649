import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('encouragement-section__slider')[0]) {
  const encouragementSlidersArr = document.querySelectorAll(
    '.encouragement-section__slider'
  );
  encouragementSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    /*let loop = false;
    let loopedSlides = false;*/

    /*if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let encouragementSwiper;

    function initSwiper() {
      encouragementSwiper = new Swiper(sliderEl, {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 25,
        threshold: 10,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
          nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
        },
        breakpoints: {
          551: {
            spaceBetween: 16,
            slidesPerView: 1.3,
          },
          651: {
            spaceBetween: 20,
            slidesPerView: 1.3,
          },
          901: {
            spaceBetween: 25,
            slidesPerView: 1.5,
          },
        },
      });

      /*if (!sliderEl.classList.contains('init-observer')) {
        sliderEl.classList.add('init-observer');
        swiperObserver(foundationsSwiper);
      }*/
    }

    function handleResize() {
      if (window.innerWidth <= 951) {
        initSwiper();
      } else {
        if (encouragementSwiper) {
          encouragementSwiper.destroy();
        }
      }
    }

    initSwiper();
    handleResize();

    window.addEventListener('resize', handleResize);
  });
}

if(document.querySelector('.encouragement-section__item.accordion-type')) {
  const encouragementItemsArr = document.querySelectorAll('.encouragement-section__item.accordion-type');

  encouragementItemsArr.forEach((item, i) => {
    const btn = item.querySelector('.encouragement-section__item-more');
    const content = item.querySelector('.encouragement-section__hidden-item-text');

    btn.addEventListener('click', () => {
      if (!item.classList.contains('active')) {
        item.classList.add('active');
        btn.textContent = 'Close more';
      } else {
        item.classList.remove('active');
        btn.textContent = 'Read more';
      }
    })
  })
}
